/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";


export default function Logo3D(props) {
  const { nodes, materials } = useGLTF("/logo-3d.glb");
  const geometry = nodes["rect254-3"].geometry
  geometry.center()
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={geometry}
        material={nodes["rect254-3"].material}
        scale={44.28}
        // position={[-1.5, 0, 2]}
      />
    </group>
  );
}

useGLTF.preload("/logo-3d.glb");
